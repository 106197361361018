"use client";
import { useRouter } from "next/navigation";
import { Button } from "../components";

export default function NotFound() {
  const router = useRouter();
  return (
    <div className="w-2/4 h-screen flex flex-col items-center justify-center gap-5 mx-auto">
      <h2>Not Found</h2>
      <p>Could not find requested resource</p>
      <Button variant="primary" radius="xl" onClick={() => router.back()}>
        Back
      </Button>
    </div>
  );
}
